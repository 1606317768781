<template>
  <div>
    <!-- name -->
    <el-tooltip
      :show-after="600"
      class="box-item"
      effect="dark"
      :content="row.name"
      placement="top"
    >
      <p class="text-xl mt-2 truncate">
        {{ row.name }}
      </p>
    </el-tooltip>

    <!-- 種類 subject -->

    <el-tooltip
      :show-after="600"
      class="box-item"
      effect="dark"
      :content="getJoinString(row.subject, 'name')"
      placement="top"
    >
      <div
        class="text-primary text-sm mt-2 truncate"
        :alt="getJoinString(row.subject, 'name')"
      >
        {{ getJoinString(row.subject, 'name') }}
      </div>
    </el-tooltip>

    <el-tooltip
      :show-after="600"
      class="box-item"
      effect="dark"
      :content="row.category?.name"
      placement="top"
    >
      <div class="text-gray-600 text-xs mt-1 line-clamp-2" alt="">
        <span>{{ row.date }}</span>
        <span class="ml-1">{{ row.category?.name }}</span>
      </div>
    </el-tooltip>

    <el-tooltip
      :show-after="600"
      class="box-item"
      effect="dark"
      :content="row.title?.name"
      placement="top"
    >
      <div
        class="text-gray-600 text-xs mt-1 line-clamp-2"
        :title="row.title?.name"
      >
        {{ row.title?.name }}
      </div>
    </el-tooltip>
    <div
      v-if="!$route.params.slug === 'topic'"
      class="text-gray-600 text-xs mt-1"
      :title="getJoinString(row.subTitle, 'name')"
    >
      {{ getJoinString(row.subTitle, 'name') }}
    </div>
  </div>
</template>

<script>
import { getJoinString } from '@/utils';
import { ElTooltip } from 'element-plus';

export default {
  components: { ElTooltip },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    return { getJoinString };
  },
};
</script>

<style></style>
