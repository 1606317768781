import Axios from './http';
const prefix = '/consumer/v1';
export default class Folder {
  static async add(params) {
    return Axios(prefix + '/exam/folder/addFolder', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async questions(params) {
    return Axios(prefix + '/exam/folder/viewQuestions', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  static async list(params) {
    return Axios(prefix + '/exam/folder/checkRecord', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  static async listById(params) {
    return Axios(prefix + '/exam/folder/folderId/' + params.folderId, {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async update(params) {
    return Axios(prefix + '/exam/folder/updFolder', {
      method: 'patch',
      responseType: 'json',
      data: params,
    });
  }
}
