<template>
  <div ref="dropdownRef" class="relative inline-block">
    <div class="cursor-pointer" @click.prevent="toggle()">
      <slot name="button">
        <El-Button type="primary">{{ text }}</El-Button>
      </slot>
    </div>

    <div
      v-show="show"
      class="absolute mt-2 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50 min-w-[200px] md:bottom-full md:mb-4 md:w-[calc(100vw-1rem)] md:-mr-2"
      :class="cls"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
    >
      <slot name="default" :toggle="toggle"></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, nextTick } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { ElButton } from 'element-plus';

export default defineComponent({
  name: 'DropDown',
  components: { ElButton },
  props: {
    text: {
      type: String,
      default: '',
    },
    cls: {
      type: String,
      default: 'origin-top-right right-0',
    },
  },

  setup() {
    const show = ref(false);
    const dropdownRef = ref(null);
    const disableHide = ref(false);

    const setDisableHide = (disabled) => {
      disableHide.value = disabled;
    };

    const toggle = (_toggle) => {
      show.value = _toggle !== undefined ? _toggle : !show.value;
    };

    onClickOutside(dropdownRef, () => {
      if (!show.value) return;
      console.log('click out:' + disableHide.value);
      if (disableHide.value) {
        return;
      }
      if (show.value) {
        show.value = false;
      }
    });

    return {
      setDisableHide,
      show,
      toggle,
      dropdownRef,
    };
  },
});
</script>
