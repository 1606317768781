import { reactive, onMounted } from 'vue';
import Subject from '@/api/Subject';

export default () => {
  const opts = reactive([]);
  const fetcher = () => {
    Subject.type().then((res) => {
      if (res.code === 1) {
        opts.splice(0, opts.length, ...res.data.rows);
        // model.value = opts[0].key;
      } else {
        // ElMessage('項目不存在');
      }
    });
  };
  onMounted(() => {
    fetcher();
  });

  return { opts };
};
