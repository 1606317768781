<template>
  <div>
    <div class="flex items-center py-4">
      <font-awesome-icon :icon="['fas', 'star']" class="mr-2 text-yellow" />
      <Tabs
        :model-value="$route.query.subType || form.subType || 'recommend'"
        :opts="opts"
        @update:modelValue="(e) => (form.subType = e)"
      />
    </div>
    <div v-loading="loading">
      <!-- 未使用 -->
      <Grid :rows="rowsData.rows" :empty-text="emptyText"></Grid>
    </div>
  </div>
</template>

<script>
import { ref, provide, watch } from 'vue';
import Exam from '@/api/Exam.js';
import useExam from '@/modules/exam/useExam';
import Grid from './Grid.vue';
import { useRoute } from 'vue-router';
import Tabs from '@/components/tab/Buttons';

export default {
  components: {
    Grid,
    Tabs,
  },
  props: {
    emptyText: {
      type: String,
      default: '目前沒有內容',
    },
  },
  setup() {
    const route = useRoute();
    const opts = [
      { id: 'recommend', name: '推薦試卷' },
      { id: 'new', name: '最新試卷' },
      { id: 'ranking', name: '本(月/周)排行' },
    ];
    if (route.params.slug !== 'topic') {
      opts.push({ id: 'coming', name: '即將考試' });
    }
    const exams = useExam({
      method: ref(Exam.recommend),
      defaultForm: {},
    });
    exams.fetcher();

    provide('exams', exams);

    const { saveds, updateSaved } = exams;
    provide('actions', { saveds, updateSaved });

    return {
      rowsData: exams.rowsData,
      loading: exams.loading,
      form: exams.form,
      opts,
    };
  },
};
</script>
