<template>
  <div>
    <h2 class="sub-title">考試單位 / 類科 :</h2>
    <div v-if="opts.length" class="">
      <div v-for="opt in opts" :key="opt.id" class="inline-block mr-4 mb-3">
        <Drop-Checkbox
          v-model="title"
          :text="opt.name"
          :opts="opt.subTitle"
          :value-key="'id'"
          :btn-type="'info'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { watch, toRefs, computed } from 'vue';

import DropCheckbox from '@/components/form/DropCheckbox.vue';
import useComputed from '../useComputed';
import useOpts from './useOpts';
// import { ElMessage } from 'element-plus';
export default {
  components: {
    DropCheckbox,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    cKey: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, cKey } = toRefs(props);

    const { opts } = useOpts(cKey);

    const subTitleOpts = computed(() => {
      const temps = [];
      opts.forEach((x) => {
        temps.push(...x.subTitle);
      });
      return temps;
    });

    const title = useComputed(modelValue, emit, subTitleOpts);

    watch(cKey, (val, old) => {
      if (val !== old) {
        title.value = [];
      }
    });

    return { title, opts, subTitleOpts };
  },
};
</script>
