<template>
  <div class="flex items-center">
    <el-table
      :data="rows"
      style="width: 100%"
      header-cell-class-name="text-gray-light font-normal text-sm"
      :cell-class-name="status"
      @row-click="preview"
    >
      <!-- 編號 -->
      <el-table-column prop="id" label="試卷ID" width="120" />

      <!-- 考試單位 -->
      <el-table-column label="考試種類/單位/類科">
        <template #default="scope">
          <div class="text-gray-light text-xs">
            {{ scope.row.category.name }}
          </div>
          <div class="text-black">
            {{ scope.row.title.name }}
          </div>
          <div class="text-black">
            {{ scope.row.subTitle[0].name }}
          </div>
        </template>
      </el-table-column>

      <!-- 試卷年分 -->
      <el-table-column
        prop="date"
        label="考試日期"
        class-name="text-gray-light"
        width="100"
      />

      <!-- 試卷名稱 -->
      <el-table-column prop="name" label="試卷名稱" class-name="text-black" />

      <el-table-column label="題數" prop="qCnt" width="65"> </el-table-column>

      <!-- 專業科目 -->
      <el-table-column label="考試科目">
        <template #default="scope">
          <div class="text-gray-light">
            {{ getJoinString(scope.row.subject, 'name') }}
          </div>
        </template>
      </el-table-column>

      <!-- 答題正確率 -->
      <el-table-column label="平均正確率" width="110">
        <template #default="scope">
          <Score :score="scope.row.avgCorrectRate"></Score>
        </template>
      </el-table-column>

      <!-- 收藏 -->
      <el-table-column label="收藏" width="65" prop="saved">
        <template #default="scope">
          <LikeBtn
            :selected="saveds.indexOf(scope.row.id) > -1"
            :circle="true"
            @toggle="
              (saved) => toggleLike({ id: scope.row.id, saved, row: scope.row })
            "
          />
        </template>
      </el-table-column>

      <!-- 收藏 -->
      <el-table-column v-if="addable" label="加入測驗" width="65" prop="added">
        <template #default="scope">
          <AddBtn
            :selected="addeds.indexOf(scope.row.id) > -1"
            :circle="true"
            @toggle="(select) => toggleAdd(scope.row, select)"
          />
        </template>
      </el-table-column>
      <template #empty>
        <div class="text-center">{{ emptyText }}</div>
      </template>
    </el-table>
    <el-dialog
      v-model="previewDialog.state.show"
      custom-class="md:w-[94%] w-[900px]"
      :destroy-on-close="true"
    >
      <PreviewList
        :columns="['attribute']"
        :clickable="true"
        :sortable="false"
      ></PreviewList>
    </el-dialog>
  </div>
</template>

<script>
import { inject, ref, provide } from 'vue';
import Score from '@/components/Score.vue';
import LikeBtn from '@/components/LikeBtn.vue';
import AddBtn from '@/components/AddBtn.vue';
import { getJoinString } from '@/utils';

import { ElTable, ElTableColumn } from 'element-plus';

import PreviewList from '@/modules/question/List.vue';
import usePreview from '@/modules/exam/usePreview';
import useDialog from '@/hook/useDialog';
import { ElDialog } from 'element-plus';
export default {
  components: {
    ElTable,
    ElTableColumn,
    ElDialog,
    Score,
    LikeBtn,
    AddBtn,
    PreviewList,
  },
  props: {
    emptyText: {
      type: String,
      default: '目前沒有資料',
    },
    rows: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    // const { saveds, updateSaved } = inject('actions');
    const examAction = inject('actions');
    const permission = inject('permission');
    const folder = inject('folder');
    const loading = ref(false);
    const toggleLike = async ({ id, saved, row }) => {
      // if (!(await permission.dialog(row.member_status))) return;

      loading.value = true;
      examAction.updateSaved({ id, saved });
      loading.value = false;
    };

    const toggleAdd = async (row, select) => {
      if (!(await permission.dialog(row.member_status))) return;
      loading.value = true;
      if (folder) {
        await folder.toggle(row, select);
        folder.open();
      }

      loading.value = false;
    };
    const status = ({ row, column }) => {
      return !permission.check(row.member_status) &&
        column.property !== 'saved' &&
        column.property !== 'added'
        ? 'soft-disabled'
        : '';
    };

    const previewDialog = useDialog();

    const {
      rows: questions,
      preview,
      loading: previewLoading,
      confs,
      updateConfs,
      saveds,
      updateSaved,
    } = usePreview({ dialog: previewDialog });

    provide('questions', {
      loading: previewLoading,
      rowsData: {
        rows: questions,
      },
      confs,
      updateConfs,
      saveds,
      updateSaved,

      isEnding: true,
    });
    return {
      getJoinString,
      toggleLike,
      toggleAdd,
      saveds: examAction.saveds,
      addeds: folder && folder.ids,
      addable: !!folder,
      status,
      preview,
      previewDialog,
    };
  },
};
</script>

<style lang="sass" scoped>

:deep(.el-table)
  .cell
    word-break: break-word
</style>
