<template>
  <div
    v-loading="loading"
    class="flex flex-col divide-dotted divide-y max-h-[300px] pr-4 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 w-[400px] md:w-full"
  >
    <div v-for="row in rows" :key="row.key" class="py-4">
      <div class="relative">
        <Info :row="row" class="pr-12"></Info>
        <TrashBtn
          class="absolute top-0 right-1"
          @click="remove(row)"
        ></TrashBtn>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import Info from '../card/Info.vue';
import TrashBtn from '@/components/TrashBtn';
import { getJoinString } from '@/utils';

export default {
  components: {
    Info,
    TrashBtn,
  },
  setup() {
    const { rows, remove, loading } = inject('folder');
    return { rows, remove, getJoinString, loading };
  },
};
</script>
