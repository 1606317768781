<template>
  <div>
    <h2 class="sub-title"><span data-tour-step="title"></span> 考試種類 :</h2>
    <Radio-Group v-model="model" class="separate h-[32px]">
      <El-Radio-Button
        v-for="opt in opts"
        :key="opt.id"
        :label="opt.id"
        type="info"
        class="mb-4"
      >
        {{ opt.name }}
      </El-Radio-Button>
    </Radio-Group>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import RadioGroup from '@/components/form/RadioGroup'; //這個有增加取消功能
import { ElRadioGroup, ElRadioButton } from 'element-plus';
import useComputed from '../useComputed';
import useOpts from './useOpts';
export default {
  components: {
    RadioGroup,
    // ElRadioGroup,
    ElRadioButton,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const { opts } = useOpts();
    const model = useComputed(modelValue, emit, opts);

    return { model, opts };
  },
};
</script>
