import { watch } from 'vue';
import _ from 'lodash';
export default (model, form, option = { deep: true, immediate: true }) => {
  watch(
    model,
    (val) => {
      if (_.isArray(val)) {
        Object.keys(form).forEach((key, i) => {
          if (val[i] && !_.isEqual(form[key], val[i])) {
            form[key] = val[i];
          }
        });
      } else {
        Object.assign(form, val);
      }
    },
    option,
  );
};
