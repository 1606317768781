<template>
  <div
    class="w-full h-[160px] bg-center bg-white flex items-center flex-shrink-0 md:h-auto py-12 relative"
  >
    <div class="container relative z-10">
      <h1 class="text-3xl">{{ title }}</h1>
      <div class="mt-3 text-sm space-x-3">
        <span>
          <router-link to="/" class="text-primary">首頁</router-link>
        </span>
        <template v-for="(breadcrumb, i) in breadcrumbs" :key="breadcrumb">
          <span>></span>
          <span :class="{ 'text-gray': i === breadcrumb.length - 1 }">
            {{ breadcrumb }}</span
          >
        </template>
      </div>
    </div>

    <img
      v-if="pcBg"
      :src="pcBgImg"
      alt=""
      class="absolute h-full w-full top-0 left-0 object-cover md:hidden"
    />
    <img
      v-if="mobileBg"
      :src="mobileBgImg"
      alt=""
      class="absolute h-full w-full top-0 left-0 object-contain object-right hidden md:block"
    />
  </div>
</template>

<script setup>
import { inject } from 'vue';

const { breadcrumbs, title, pcBg, mobileBg } = inject('page');

const pcBgImg = require(`@/assets/imgs/${pcBg.value}`);
const mobileBgImg = require(`@/assets/imgs/${mobileBg.value}`);
</script>

<style lang="sass" scoped></style>
