<template>
  <div class="flex items-center justify-between">
    <p class="text-xs text-gray-400">
      <span class="text-black">{{ row.ansCnt }}</span> 次作答，共<span
        class="text-black"
        >{{ row.qCnt }}</span
      >
      題
    </p>

    <!-- actions -->
    <div class="flex space-x-2">
      <LikeBtn
        :selected="saveds?.indexOf(row.id) > -1"
        @toggle="
          (saved) =>
            toggleLike({
              id: row.id,
              saved,
            })
        "
      />
      <!-- tour應該要改成外部搜尋 -->
      <AddBtn
        v-if="addable"
        class="addBtn"
        :loading="loading"
        :selected="addeds.indexOf(row.id) > -1"
        text="加入測驗"
        @toggle="toggleAdd"
      />
    </div>
  </div>
</template>

<script>
import { inject, toRefs, ref } from 'vue';

import LikeBtn from '@/components/LikeBtn.vue';
import AddBtn from '@/components/AddBtn.vue';

export default {
  components: {
    LikeBtn,
    AddBtn,
  },
  props: {
    idx: {
      type: Number,
      default: 0,
    },
    row: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { row } = toRefs(props);
    const permission = inject('permission');
    const { saveds, updateSaved } = inject('actions');
    const folder = inject('folder');

    const toggleLike = async ({ id, saved }) => {
      // if (!(await permission.dialog(row.member_status))) return;
      updateSaved({ id, saved });
    };

    const loading = ref(false);

    const toggleAdd = async (select) => {
      if (!(await permission.dialog(row.value.member_status))) return;
      loading.value = true;
      if (folder) {
        await folder.toggle(row.value, select);
        folder.open();
      }

      loading.value = false;
    };

    return {
      toggleAdd,
      toggleLike,
      saveds,
      addeds: folder && folder.ids,
      addable: !!folder,
      loading,
      permission,
    };
  },
};
</script>

<style></style>
