<template>
  <div class="flex">
    <el-input
      v-model.number="model"
      class="w-28"
      :precision="0"
      :min="min"
      :max="max"
      :size="size"
      :controls="false"
      @input="check"
    >
      <template #prefix>
        <div v-if="prefix">
          {{ prefix }}
        </div>
      </template>
      <template #suffix>
        <div v-if="suffix">
          {{ suffix }}
        </div>
      </template>
    </el-input>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import useComputed from '@/hook/useComputed';
import { ElInput, ElInputNumber } from 'element-plus';
export default {
  components: {
    // ElInput,
    ElInput,
  },
  props: {
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'default',
    },
    modelValue: {
      type: [Number, String],
      default: 0,
    },
  },
  emits: ['update:modelValue', 'update'],
  setup(props, { emit }) {
    const { modelValue, max, min } = toRefs(props);

    const model = useComputed(modelValue, emit);
    const check = (val) => {
      if (val > max.value) {
        model.value = max.value;
      } else if (val < min.value) {
        model.value = min.value;
      }
    };
    return { model, check };
  },
};
</script>

<style lang="sass" scoped>
:deep
  .el-input__inner
    padding-right: 0
  .el-input--prefix .el-input__inner
    padding-left: 40px
</style>
