import {
  ref,
  inject,
  computed,
  nextTick,
  reactive,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import MessageBox from '@/components/messageBox/Index';

import Storage from '@/utils/Storage';
import Folder from '@/api/Folder';
import _ from 'lodash';

import { LIMIT } from '@/utils/Config';
import { ElMessage } from 'element-plus';
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router';
export default (params) => {
  const auth = inject('auth');
  const route = useRoute();

  const router = useRouter();
  const type = computed(() => {
    return route.query.type || route.params.slug;
  });
  const id = ref(null);
  const rows = reactive([]);
  const storageKey = ref(null);
  const tempRows = ref([]);
  const fetcher = async () => {
    let api;
    if (id.value) {
      api = Folder.listById;
    } else if (auth.state.isAuth) {
      //checkFolder
      api = Folder.list;
    } else {
      return;
    }

    const res = await api({
      type: type.value,
      folderId: id.value,
    });

    rows.length = 0;
    if (res.code === 1) {
      id.value = res.data.folderId;
      Storage.set(storageKey.value, id.value);
      rows.push(...res.data.rows);
      tempRows.value = _.cloneDeep(rows);
    } else {
      id.value = null;
      Storage.remove(storageKey.value);
    }
  };

  const ids = computed(() => {
    return rows.map((x) => x.id);
  });
  const total = computed(() => {
    return rows.reduce((a, b) => {
      return (b.qCnt || 0) + a;
    }, 0);
  });
  const loading = ref(false);

  const sync = async () => {
    loading.value = true;
    let valid = true;
    let res;

    const emKeys = tempRows.value.map((x) => x.id);
    if (!id.value) {
      res = await Folder.add({ type: type.value, emKeys });
    } else {
      res = await Folder.update({ folderId: id.value, emKeys });
    }
    if (res.code === 1) {
      if (res.data.folderId) {
        id.value = res.data.folderId;
        Storage.set(storageKey.value, id.value);
      }
      rows.splice(0, rows.length, ...tempRows.value);
    } else {
      valid = false;
      ElMessage({ message: '操作失敗', type: 'error' });
    }

    loading.value = false;
    return Promise.resolve(valid);
  };

  const add = async (row) => {
    let valid = true;

    if (total.value + (row.qCnt || 0) <= LIMIT) {
      const idx = rows.findIndex((x) => row.id === x.id);

      if (idx < 0) {
        tempRows.value.unshift(row);

        const res = await sync();

        if (!res) {
          valid = false;
        }
      } else {
        valid = false;
      }
    } else {
      ElMessage(`題目數量超過限制(${LIMIT})`);
      valid = false;
    }
    return valid;
  };
  const remove = async (row) => {
    let valid = true;
    const idx = rows.findIndex((x) => x.id === row.id);
    if (idx > -1) {
      tempRows.value.splice(idx, 1);
      const res = await sync();
      if (!res) {
        valid = false;
      }
    } else {
      valid = false;
    }
    return valid;
  };

  const toggle = async (row, select) => {
    loading.value = true;
    if (select) {
      await add(row);
    } else {
      await remove(row);
    }
    loading.value = false;
    return Promise.resolve(true);
  };

  const removeAll = async () => {
    await MessageBox.confirm('確定全部刪除?');

    tempRows.value.splice(0, tempRows.value.length);
    const res = await sync();
  };

  const watchAll = () => {
    if (!id.value) {
      // ElMessage.error('沒有Folder Id');
      console.log('沒有Folder Id');
      return;
    }
    router.push({
      // path:route.query.redirectUri
      name: params.listRouteName || 'Exam.Question',
      params: { slug: route.query.type || route.params.slug },
      query: {
        folderId: id.value,
        redirectUri: encodeURIComponent(route.fullPath),
        _exItemHide: 1,
      },
    });
  };
  const leaving = ref(false);
  onBeforeRouteLeave((to, from) => {
    leaving.value = true;
  });
  watch(
    [() => auth.user, type],
    (val, old) => {
      if (leaving.value) return;
      if (!val || _.isEqual(val, old)) return;

      storageKey.value =
        (val[0].accountId || 'customer') + '_' + type.value + '_folder_id';
      id.value = Storage.get(storageKey.value);
      fetcher();
    },
    { immediate: true, deep: true },
  );
  const dropdownRef = ref(null);

  const open = () => {
    dropdownRef.value.toggle(true);
  };

  const close = () => {
    dropdownRef.value.toggle(false);
  };

  const setDisableHide = (disabled) => {
    if (dropdownRef.value) {
      dropdownRef.value.setDisableHide(disabled);
    }
  };

  return {
    id,
    rows,
    add,
    remove,
    removeAll,
    ids,
    type: type.value,
    toggle,
    fetcher,
    total,
    loading,
    watchAll,
    dropdownRef,
    open,
    close,
    setDisableHide,
  };
};
