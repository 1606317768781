<template>
  <div v-if="status" class="fixed top-0 left-0 w-full h-full z-[9999]">
    <div class="overlay"></div>
    <v-tour :name="key" :steps="rows" :options="_options" :callbacks="cb" />
  </div>
</template>

<script setup>
import {
  defineProps,
  onMounted,
  getCurrentInstance,
  defineEmits,
  onUnmounted,
  ref,
} from 'vue';

const emit = defineEmits(['onSkip', 'onFinish', 'onEnd', 'onNextStep']);
const props = defineProps({
  key: {
    type: String,
    default: 'tour',
  },
  rows: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Object,
    default: () => {},
  },
});

const _options = {
  startTimeout: 1000,
  labels: {
    buttonSkip: '略過',
    buttonPrevious: '上一步',
    buttonNext: '下一步',
    buttonStop: '完成',
  },
  ...props.options,
};

const cb = {
  onSkip: (currentStep) => {
    emit('onSkip', currentStep);
    clear(currentStep);
  },
  onFinish: (currentStep) => {
    emit('onFinish', currentStep);
    clear(currentStep);
  },
  onNextStep: (currentStep) => {
    emit('onNextStep', currentStep);
  },
};
const clear = (currentStep) => {
  status.value = false;

  emit('onEnd', currentStep);
};

const status = ref(true);
const vm = getCurrentInstance();
onMounted(() => {
  vm.proxy.$tours[props.key].start();
});
onUnmounted(() => {
  vm.proxy.$tours[props.key].stop();
});
</script>

<style lang="scss" scoped>
:deep(.v-tour) {
  @apply pointer-events-none;
  .v-step__arrow::before {
    background-color: #fff !important;
  }
  .v-step {
    // padding: 1rem 2rem!important;
    padding-left: 120px;
    max-width: 400px;
    background-color: #fff !important;
    color: #000 !important;
    box-shadow: rgba(0, 0, 0, 0.4) 0 0 20px 3px,
      rgba(0, 0, 0, 0.4) 0 4px 6px -1px, rgba(0, 0, 0, 0.1) 0 2px 4px -1px !important;
    &::after {
      content: '';
      position: absolute;
      top: 5%;
      left: 20px;
      width: 80px;
      height: 90%;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }

    .v-step__header {
      font-size: 20px;
      margin: 0;
      text-align: left;
      background-color: #fff;
      padding: 0;
      margin-bottom: 0.5em;
      font-weight: bold;
    }
    .v-step__content {
      text-align: left;
      line-height: 1.75em;
      font-weight: 400;
      margin-bottom: 1.6em;
    }
    .v-step__buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      .v-step__button {
        border: 1px solid var(--el-color-primary);
        border-radius: 4px;
        color: var(--el-color-primary);
        padding: 0.6em 1.2em;
        height: auto;
        &.v-step__button-skip {
          border: 1px solid transparent;
        }
        &:hover {
          background-color: var(--el-color-primary-light);
        }
      }
    }
  }
}

.overlay {
  @apply fixed w-screen h-screen left-0 top-0  block  bg-black bg-opacity-10
  pointer-events-none;
}
</style>
