import Exam from '@/api/Exam';
import { reactive, watch } from 'vue';

export default () => {
  const opts = reactive([]);
  const fetcher = () => {
    Exam.category().then((res) => {
      if (res.code === 1) {
        opts.splice(0, opts.length, ...res.data.rows);
      }
    });
  };
  fetcher();

  return { opts };
};
