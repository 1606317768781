<template>
  <Dropdown ref="dropdownRef" class="md:fixed md:bottom-4 md:right-4 md:z-40">
    <template #button>
      <El-Badge
        :value="rows.length"
        :max="99"
        class="badge"
        :hidden="rows.length == 0"
      >
        <El-Button
          :loading="loading"
          size="large"
          circle
          type="primary"
          class="mini-folder"
        >
          <img src="@/assets/imgs/file.svg" alt />
        </El-Button>
      </El-Badge>
    </template>

    <div class="p-4">
      <List></List>

      <div>
        <div
          v-if="rows.length === 0"
          class="text-gray text-sm whitespace-nowrap text-center pb-4"
        >
          目前沒有試卷
        </div>
        <div class="flex justify-center items-center border-t border-dotted">
          <span class="py-5 text-gray">
            一共有
            <i class="text-black"> {{ rows.length }}</i> 份試卷，<i
              class="text-black"
              >{{ total }}</i
            >
            試題</span
          >
          <span
            class="underline text-xs ml-4 hover:text-warning cursor-pointer"
            @click="removeAll"
            >清除全部</span
          >
        </div>
        <div class="flex justify-center">
          <Btn-Start
            id="btn-start"
            :disabled="!id || rows.length === 0"
            :type="type"
            :need-confirm="true"
            :em-keys="rows.map((x) => x.id)"
          />
          <El-Button
            id="btn-watch"
            :disabled="!id || rows.length === 0"
            class="ml-2"
            type="primary"
            @click="watchAll"
            >查看問題列表
          </El-Button>
        </div>
      </div>
    </div>
  </Dropdown>
</template>

<script>
import { Delete } from '@element-plus/icons-vue';
import Dropdown from '@/components/form/Dropdown.vue';
import { inject } from 'vue';
import { ElBadge } from 'element-plus';
import BtnStart from '@/modules/tester/BtnStart';

import List from './List.vue';

export default {
  components: {
    List,
    Dropdown,
    ElBadge,
    BtnStart,
  },
  props: {
    question: {
      type: String,
      default: 'Exam.Question',
    },
  },
  setup(props) {
    const { rows, id, removeAll, type, total, loading, watchAll, dropdownRef } =
      inject('folder');

    return {
      watchAll,
      rows,
      removeAll,
      Delete,
      type,
      total,
      id,
      loading,
      dropdownRef,
    };
  },
};
</script>

<style lang="sass" scoped>
:deep
  .el-badge__content
    @apply mt-1 mr-1
.mini-folder
  @apply p-[8px] md:w-16 md:h-16
</style>
