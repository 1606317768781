<template>
  <div>
    <h2 class="sub-title">答題正確率:</h2>
    <Radio v-model="model" :opts="opts" :others-default="[0, 100]">
      <template #default="slot">
        <div class="flex items-center">
          <Radio-Input v-model="slot.others[0]" :suffix="'%'" size="small" />
          <span class="mx-2">－</span>
          <Radio-Input v-model="slot.others[1]" :suffix="'%'" size="small" />
        </div>
      </template>
    </Radio>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import Radio from '@/components/form/radioInput/Index.vue';
import RadioInput from '@/components/form/radioInput/Input.vue';
import useComputed from '../useComputed';
import useOpts from './useOpts';

export default {
  components: {
    Radio,
    RadioInput,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const { opts } = useOpts();
    const model = useComputed(modelValue, emit, opts);

    return { model, opts };
  },
};
</script>
