<template>
  <div class="mx-2">
    <El-Button
      :type="$route.query?.filterId ? 'info' : 'default'"
      @click="$emit('toggle')"
      >進階搜尋</El-Button
    >
    <el-button v-if="$route.query?.filterId" @click="$emit('reset')"
      >重設</el-button
    >
  </div>
</template>

<script>
// import { Check } from '@element-plus/icons-vue';

export default {
  emits: ['toggle', 'reset'],

  setup(props) {
    return {};
  },
};
</script>
