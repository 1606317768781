import Subject from '@/api/Subject';
import { reactive } from 'vue';

export default (key) => {
  const opts = reactive([
    {
      key: 'all',
      name: '不拘',
    },
    {
      key: '1m',
      name: '近1個月',
    },
    {
      key: '3m',
      name: '近三個月',
    },
    {
      key: '6m',
      name: '近半年',
    },
    {
      key: '1y',
      name: '近一年',
    },
    {
      key: '2y',
      name: '近兩年',
    },
    {
      key: '3y',
      name: '近三年',
    },
    {
      key: '5y',
      name: '近五年',
    },
    {
      key: '10y',
      name: '近十年',
    },
    {
      key: 'custom',
      name: '自訂',
    },
  ]);

  return { opts };
};
