import Axios from './http';
const prefix = '/consumer/v1';
export default class Filter {
  static async scopes(params) {
    return Axios(prefix + '/filter/scopes', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
