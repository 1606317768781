import { nextTick, computed, reactive, ref, watch, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useRows from '@/hook/useRows';
import Exam from '@/api/Exam';
import _ from 'lodash';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import useSaved from '@/modules/saved/useSaved';
export default ({ method = Exam.recommend, defaultForm = {} }) => {
  const route = useRoute();

  const {
    form: saveds,
    update: _updateSaved,
    set: setSaveds,
  } = useSaved({ api: 'exam' });

  const exam = useRows({
    method: method,
    defaultForm: {
      type: route.query.type || route.params.slug,
      orderItem: 'date',
      order: '1',
      ...defaultForm,
    },
    cb: (res) => {
      if (res.code === 1 && res.data.rows) {
        setSaveds(res.data.rows);
      }
    },
  });

  const updateForm = (params) => {
    if ('order' in params) {
      params.order = '' + params.order;
    }

    Object.assign(exam.form, { ...params });
  };

  const leaving = ref(false);
  watch(
    () => _.cloneDeep(exam.form),
    (val, old) => {
      if (leaving.value) return;
      if (_.isEqual(val, old)) return;
      if (val.keyword && !old?.keyword) {
        exam.form.filterId = null;
      }

      exam.fetcher();
      exam.syncQuery();
    },
    { immediate: false, deep: true },
  );

  onBeforeRouteLeave((to, from) => {
    leaving.value = true;
  });

  const updateSaved = ({ id, saved }) => {
    _updateSaved({
      id,
      saved,
      type: route.params.slug || route.query.type || 'past',
    });
  };

  return { ...exam, saveds, updateSaved, updateForm };
};
