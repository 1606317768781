import { computed, isRef } from 'vue';
import _ from 'lodash';
export default (modelValue, emit, opts = [], method = 'update:modelValue') => {
  return computed({
    get() {
      return _.isArray(modelValue.value)
        ? modelValue.value.map((x) => x.id)
        : modelValue.value?.id;
    },
    set(val) {
      if (!opts) {
        console.log('沒有名稱參考選單opts');
        return;
      }

      let dataWithProp;
      const _opts = isRef(opts) ? opts.value : opts;

      if (_.isArray(val)) {
        if (!val) {
          dataWithProp = [];
        } else {
          dataWithProp = val.map((x) => {
            const idx = _opts.findIndex((y) => {
              return y.id == x;
            });

            return { ..._opts[idx], id: x };
          });
        }
      } else {
        if (!val) {
          dataWithProp = null;
        } else {
          const idx = _opts.findIndex((x) => x.id === val);
          dataWithProp = idx > -1 ? _opts[idx] : { id: val, name: val };
        }
      }

      emit(method, dataWithProp);
    },
  });
};
