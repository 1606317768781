<template>
  <el-tooltip
    :content="selected ? '取消收藏試卷' : '加入收藏試卷'"
    placement="top"
  >
    <a
      href="#"
      class="border py-2 px-3 text-gray cursor-pointer duration-300 group hover:text-gray-800 border-gray-400 hover:border-gray-800 inline-flex justify-center items-center"
      :class="[
        selected ? ' bg-white text-warning' : '',
        circle ? 'rounded-full w-10 h-10 ' : 'rounded',
      ]"
      @click.prevent.stop="toggle(!selected)"
    >
      <font-awesome-icon
        :class="{ 'group-hover:text-gray-800': !selected }"
        :icon="selected ? ['fas', 'heart'] : ['far', 'heart']"
      />
    </a>
  </el-tooltip>
</template>
<script>
import { ElDialog, ElTooltip } from 'element-plus';

export default {
  components: {
    ElTooltip,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const toggle = (select) => {
      emit('toggle', select);
    };
    return { toggle };
  },
};
</script>
