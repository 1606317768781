<template>
  <Tour
    v-if="show === true"
    :rows="rows"
    :image="`url(~@/assets/imgs/analysisRole5.png)`"
    @onEnd="onEnd"
  ></Tour>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue';
import Tour from '@/modules/tour/Index.vue';
const { done, status } = inject('tour');

const folder = inject('folder');
const dialog = inject('dialog');

const rows = [
  {
    target: '#btnFilter',
    header: {
      title: '開啟試卷篩選清單',
    },
    content: `點擊”進階篩選”開啟篩選清單，輸入條件找到符合需求的試卷。`,
    params: {
      enableScrolling: false,
    },
    before: (type) =>
      new Promise((resolve, reject) => {
        dialog.toggle({ show: false });
        resolve();
      }),
  },
  {
    target: '[data-tour-step="title"]',
    header: {
      title: '選擇考試的種類/單位/類科',
    },
    content: `利用篩選清單選擇特定考試種類/單位/類科，讓篩選結果更精準。`,
    params: {
      enableScrolling: false,
      placement: 'top-start',
    },
    before: (type) =>
      new Promise((resolve, reject) => {
        dialog.toggle({ show: true });
        resolve();
      }),
  },
  {
    target: '#btnAddFilters',
    header: {
      title: '選擇加入篩選條件',
    },
    content: `選擇完篩選項目後，點擊"加入篩選條件"。`,
    params: {
      enableScrolling: true,
    },
  },
  {
    target: '#btnAddFilters',
    header: {
      title: '可加入多個篩選條件',
    },
    content: `加入多個篩選條件，一次撈出符合的試卷！`,
    params: {
      enableScrolling: true,
    },
  },
  {
    target: '#btnGetFilterResult',
    header: {
      title: '顯示篩選結果',
    },
    content: `確認篩選條件正確後，點擊”顯示試卷”，就會出現所有符合條件的試卷。`,
    params: {
      enableScrolling: true,
    },
  },
  {
    target: '.card-0',
    header: {
      title: '加入想練習的試卷',
    },
    content: `點擊”加入測驗”將該份試卷加入資料夾。`,
    params: { enableScrolling: true },
    before: (type) =>
      new Promise((resolve, reject) => {
        dialog.toggle({ show: false });
        resolve();
      }),
  },
  {
    target: '#folder',
    header: {
      title: '加入測驗的試卷資訊',
    },
    content: `點選資料夾icon顯示加入測驗的<br>(1)試卷資訊 <br>(2)試卷/試題總數`,
    params: { enableScrolling: false, placement: 'top' },
    before: (type) =>
      new Promise((resolve, reject) => {
        window.scrollTo(0, 0);
        resolve();
      }),
  },
  {
    target: '#btn-start',
    header: {
      title: '直接開始測驗',
    },
    content: `點擊”開始測驗”後直接進入測驗模式。`,
    params: { placement: 'bottom', enableScrolling: false },
    before: (type) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          folder.open();
          resolve();
        }, 100);
      }),
  },
  {
    target: '#btn-watch',
    header: {
      title: '查看問題列表',
    },
    content: `點擊"查看問題列表"顯示資料夾中所有試題基本資訊。`,
    params: { placement: 'bottom', enableScrolling: false },
    before: (type) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          folder.open();
          resolve();
        }, 100);
      }),
  },
];
const key = 'exam';
const onEnd = () => {
  console.log('hihi');
  folder.setDisableHide(false);
  folder.close();

  done(key);
};

const show = ref(null);
onMounted(() => {
  show.value = status[key];
  if (show.value) {
    folder.setDisableHide(true);
  }
});
</script>

<style lang="sass" scoped>
:deep(.v-step)
  &::after
    background-image: url(~@/assets/imgs/analysisRole5.png)
  &#v-step-388e09c9
    max-width: 500px
    min-height: 200px
    padding-left: 250px
    &::after
      left: 0
      width: 250px
      background-image: url(~@/assets/imgs/filter-demo.jpg)
</style>
