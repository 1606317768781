import Exam from '@/api/Exam.js';
import { reactive, ref } from 'vue';
import useSaved from '@/modules/saved/useSaved';
import useConf from '@/modules/quest/conf/useConf';
export default ({ dialog }) => {
  const { confs, updateConfs, setConfs } = useConf();
  const {
    form: saveds,
    update: updateSaved,
    set: setSaveds,
  } = useSaved({ api: 'question' });

  const loading = ref(false);
  const rows = reactive([]);
  const preview = ({ id }) => {
    rows.length = 0;
    dialog.toggle({ show: true });
    loading.value = true;
    Exam.previewQuestions({ emId: id }).then((res) => {
      if (res.code === 1) {
        setConfs(res.data.rows);
        setSaveds(res.data.rows);
        rows.push(...res.data.rows);
      }
      loading.value = false;
    });
  };
  return { rows, preview, loading, saveds, updateSaved, updateConfs, confs };
};
