<template>
  <div class="flex flex-wrap">
    <a
      v-for="opt in opts"
      :key="opt.id"
      href="#"
      class="py-2 border-primary mr-4"
      :class="
        model === opt.id
          ? 'text-primary border-b-2 font-bold'
          : 'text-gray  hover:opacity-75'
      "
      @click.prevent="update(opt.id)"
      >{{ opt.name }}
    </a>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue';
import { useVModel } from '@vueuse/core';
const props = defineProps({
  opts: {
    default: () => [],
    type: Array,
  },
  modelValue: {
    default: null,
    type: String,
  },
});
const emit = defineEmits(['update:modelValue']);

const model = useVModel(props, 'modelValue', emit);
const update = (id) => {
  model.value = id;
};
</script>
