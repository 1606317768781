import { computed, onMounted, reactive } from 'vue';
import { FILTER_NAME, EXAM_TYPE_NAME } from '@/utils/Config';

import _ from 'lodash';
import { ElMessage } from 'element-plus';
import { isEmpty } from '@/utils/index';
import { useRoute } from 'vue-router';
import Filter from '@/api/Filter.js';
export default ({ defaultForm, excludeResetKeys }) => {
  const form = reactive({});
  const scopes = reactive([]);

  Object.assign(form, { ..._.cloneDeep(defaultForm) });

  const isFixSb = computed(() => {
    return scopes.length > 0;
  });
  const reset = () => {
    let tempDefaultForm = _.cloneDeep(defaultForm);

    if (isFixSb.value && excludeResetKeys?.length) {
      excludeResetKeys.forEach((key) => {
        delete tempDefaultForm[key];
      });
    }
    Object.assign(form, tempDefaultForm);
  };
  const clear = () => {
    scopes.length = 0;
  };

  const checkExist = (_form) => {
    for (const key in _form) {
      if (_.isArray(_form[key])) {
        _form[key].sort((a, b) => (a.name > b.name ? 1 : -1));
      } else if (!_form[key] || isEmpty(_form[key])) {
        delete _form[key];
      }
    }
    return scopes.some((x) => {
      return _.isEqual(x, _form);
    });
  };

  let scopesTemp = [];

  const changed = computed(() => {
    return !_.isEqual(scopesTemp, scopes);
  });

  const checkSubjectScopes = (_form) => {
    Object.keys(_form).forEach((key) => {
      if (key === 'chapter') {
        _form[key].forEach((chapter) => {
          let selected = [];
          let unselected = [];
          _form.section.forEach((x) => {
            if (x.cid === chapter.id) {
              selected.push(x);
            } else {
              unselected.push(x);
            }
          });

          if (selected.length === chapter.count) {
            _form.section = [...unselected];
          }
        });
      }
    });
  };

  const add = (_form) => {
    const Temp = _form ? _form : _.cloneDeep(form);
    if (checkExist(Temp)) {
      ElMessage({
        message: '篩選條件重複',
        center: true,
      });
    } else {
      //因為SubjectScopes很特殊
      if (!_form) {
        checkSubjectScopes(Temp);
      }
      scopes.push(Temp);
      reset({});
    }
  };

  const results = computed(() => {
    const tempResults = scopes.map((s, si) => {
      let textArr = [];

      // 硬加label名稱，最好是換名稱
      let prefix = s['type'] === 'past' ? '試卷' : '試題';
      Object.keys(s).forEach((key) => {
        if (!s[key] || (_.isArray(s[key]) && !s[key].length)) return;
        // if (key === 'qCnt') return; //試卷題數不列出來
        let value = '';

        if (key === 'type') {
          value = EXAM_TYPE_NAME[s[key]];
        } else {
          if (_.isArray(s[key])) {
            s[key].forEach((x, xi) => {
              value += `${xi !== 0 ? ',' : ''}${x.name}`;
            });
          } else {
            if (s[key]) {
              // value = `${s[key].name || s[key]}`;
              const _name = s[key].name;
              value = `${_name === 'all' ? '不拘' : _name || s[key]} `;
            }
          }

          if (FILTER_NAME[key]) {
            // 硬加label名稱，最好是換名稱
            let _prefix =
              ['name', 'key', 'type'].indexOf(key) > -1 ? prefix : '';
            value = `${_prefix}${FILTER_NAME[key]} ${value} `;
          }
        }
        if (!value) return;
        textArr.push(value);
      });

      return {
        id: si,
        text: textArr.join(' / '),
      };
    });

    return tempResults;
  });

  const getFilterId = ({ API }) => {
    if (!scopes.length) {
      ElMessage({
        message: '請添加篩選條件',
        center: true,
        type: 'error',
      });
      return;
    }
    const filterScopes = _.cloneDeep(scopes);
    filterScopes.forEach((s) => {
      Object.keys(s).forEach((key) => {
        if (_.isArray(s[key])) {
          s[key] = s[key].map((x) => x.id);
          // 這段似乎要移動
          if (s[key][0] === 'all') {
            s[key] = 'all';
          }
        } else if (s[key]) {
          s[key] = s[key].id || s[key];
        }
      });
    });

    return API({ type: form.type, filterScopes });
  };

  const remove = (idx) => {
    scopes.splice(idx, 1);
  };

  const removeAll = (idx) => {
    scopes.splice(0, scopes.length);
  };

  const getScopes = () => {
    const route = useRoute();
    if (!route.query.filterId || !route.params.slug) return;
    Filter.scopes({
      type: route.params.slug,
      filterId: route.query.filterId,
    }).then((res) => {
      if (res.data?.rows?.length) {
        res.data.rows.forEach((x) => {
          add({ type: res.data.type, ...x });
        });
        // console.log(scopes);

        scopesTemp = _.cloneDeep(scopes);
      }
    });
  };

  onMounted(() => {
    getScopes();
  });

  return {
    form,
    clear,
    scopes,
    results,
    getFilterId,
    add,
    reset,
    remove,
    removeAll,
    changed,

    isFixSb,
  };
};
