import Exam from '@/api/Exam';
import { reactive, watch, toRef } from 'vue';
export default (cKey) => {
  const opts = reactive([]);

  const fetcher = () => {
    Exam.title({ ckey: cKey.value }).then((res) => {
      if (res.code === 1) {
        const data = res.data.rows.filter((x) => x.subTitle.length > 0);
        opts.splice(0, opts.length, ...data);
      } else {
        opts.splice(0, opts.length);
      }
    });
  };

  watch(
    cKey,
    (val, old) => {
      if (val || val !== old) {
        fetcher();
      } else {
        opts.splice(0, opts.length);
      }
    },
    { immediate: true, deep: true },
  );

  return { opts };
};
