<template>
  <div class="flex text-gray space-x-4">
    <font-awesome-icon
      icon="grip-horizontal"
      size="lg"
      class="cursor-pointer hover:text-black duration-200"
      :class="{ 'text-black': $props.display === 'card' }"
      @click="$emit('choose-type', 'card')"
    />
    <font-awesome-icon
      icon="bars"
      size="lg"
      class="cursor-pointer hover:text-black duration-200"
      :class="{ 'text-black': $props.display === 'table' }"
      @click="$emit('choose-type', 'table')"
    />
  </div>
</template>

<script>
export default {
  props: {
    display: {
      type: String,
      default: 'card',
      require: true,
    },
  },
  emits: ['choose-type'],
};
</script>
