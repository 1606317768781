<template>
  <El-Radio-Group
    v-model="model"
    class="separate"
    size="small"
    @click="onClick"
  >
    <slot> </slot>
  </El-Radio-Group>
</template>

<script>
import { computed, toRefs } from 'vue';

import { ElRadioGroup } from 'element-plus';

export default {
  components: {
    ElRadioGroup,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    const onClick = (e) => {
      if (!e.target.value) return;
      if (model.value === e.target.value) {
        model.value = null;
      }
    };

    return { model, onClick };
  },
};
</script>
