<template>
  <div class="flex justify-between">
    <div class>
      <!-- no. -->
      <p class="text-xs text-gray">試卷ID： {{ row.id }}</p>

      <!-- tags -->
      <div class="flex -mx-1 mt-2 flex-wrap pr-4">
        <div
          v-for="(item, index) in row.tags"
          :key="index"
          class="mx-1 mb-1 rounded-full text-xs text-white bg-info px-3 py-1"
          :style="{ backgroundColor: item.color }"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
    <div class="flex flex-col items-end">
      <div class="text-gray text-xs">平均正確率</div>
      <Score :score="row.avgCorrectRate"></Score>
    </div>
  </div>
</template>

<script>
import Score from '@/components/Score.vue';

export default {
  components: {
    Score,
  },
  props: {
    row: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    return {};
  },
};
</script>

<style></style>
