<template>
  <div>
    <h2 class="sub-title">考試科目</h2>
    <El-Checkbox-Group v-model="model" class="separate">
      <El-Checkbox-Button v-for="opt in opts" :key="opt.id" :label="opt.id">
        {{ opt.name }}
      </El-Checkbox-Button>
    </El-Checkbox-Group>
  </div>
</template>

<script>
import { toRefs, watch } from 'vue';

import { ElCheckboxGroup, ElCheckboxButton, ElMessage } from 'element-plus';
import useComputed from '../useComputed';
import useOpts from './useOpts';

export default {
  components: {
    ElCheckboxGroup,
    ElCheckboxButton,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sbtKey: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, sbtKey } = toRefs(props);

    const { opts } = useOpts(sbtKey);
    const model = useComputed(modelValue, emit, opts);
    watch(
      sbtKey,
      (val, old) => {
        if (val !== old) {
          model.value = null;
        }
      },
      {
        immediate: true,
      },
    );
    return { model, opts };
  },
};
</script>
