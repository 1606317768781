<template>
  <div>
    <h2 class="sub-title">
      <span data-v-step="1">科目種類</span>
      <span v-if="required" class="text-danger text-sm ml-1">(必選)</span>
    </h2>
    <Radio-Group v-model="model" class="h-[32px]">
      <El-Radio-Button v-for="opt in opts" :key="opt.id" :label="opt.id">
        {{ opt.name }}
      </El-Radio-Button>
    </Radio-Group>
  </div>
</template>

<script>
import { watch, toRefs } from 'vue';
import RadioGroup from '@/components/form/RadioGroup'; //這個有增加取消功能
import { ElRadioButton } from 'element-plus';
import useComputed from '../useComputed';
import useOpts from './useOpts';
export default {
  components: {
    RadioGroup,
    ElRadioButton,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const { opts } = useOpts();
    const model = useComputed(modelValue, emit, opts);

    // 單一預設
    watch(opts, () => {
      if (opts.length === 1) {
        model.value = opts[0].id;
      }
    });

    return { model, opts };
  },
};
</script>
