import { reactive } from 'vue';

export default () => {
  const opts = reactive([
    {
      key: 'all',
      name: '不拘',
    },
    {
      key: '100-80',
      name: '100-80',
    },
    {
      key: '80-60',
      name: '80-60',
    },
    {
      key: '60-40',
      name: '60-40',
    },
    {
      key: '40-20',
      name: '40-20',
    },
    {
      key: '20-0',
      name: '20-0',
    },
    {
      key: 'custom',
      name: '自訂',
    },
  ]);
  return { opts };
};
