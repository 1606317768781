<template>
  <el-dropdown ref="dropdown" popper-class="filter-theme-info" trigger="click">
    <El-Badge :value="count" class="item" :hidden="count == 0">
      <El-Button :size="size" :type="count != 0 ? $props.btnType : ''">
        {{ text }}
        <font-awesome-icon
          v-if="opts.length > 0"
          :icon="['fas', 'caret-down']"
          class="ml-1"
        />
      </El-Button>
    </El-Badge>
    <template #dropdown>
      <div class="p-4">
        <div
          class="max-h-64 scrollbar-thin scrollbar-thumb-primary scrollbar-track-gray-100"
        >
          <El-Checkbox-group v-model="selected" class="flex flex-col">
            <El-Checkbox
              v-for="opt in opts"
              :key="valueKey ? opt[valueKey] : opt"
              :label="valueKey ? opt[valueKey] : opt"
              >{{ valueKey ? opt.name : opt }}</El-Checkbox
            >
          </El-Checkbox-group>
        </div>

        <div class="flex w-full pt-2 border-t mt-3 minw">
          <El-Button
            type="text"
            class="text-gray"
            size="small"
            @click="checkAll"
            >全選</El-Button
          >
          <El-Button
            type="text"
            class="text-gray ml-2"
            size="small"
            @click="uncheckAll"
            >清除全部</El-Button
          >
          <El-Button
            class="!ml-auto"
            type="primary"
            size="small"
            @click="onClose"
            >確定</El-Button
          >
        </div>
      </div>
    </template>
  </el-dropdown>
</template>
<script>
import { toRefs, ref, computed } from 'vue';
import { ElCheckboxGroup, ElCheckbox, ElBadge, ElDropdown } from 'element-plus';
import Dropdown from '@/components/form/Dropdown.vue';
import useComputed from '@/hook/useComputed';
import _ from 'lodash';
import { nextTick } from 'process';

export default {
  components: {
    // Dropdown,
    ElCheckboxGroup,
    ElCheckbox,
    ElDropdown,
    ElBadge,
  },
  props: {
    size: {
      type: String,
      default: 'default',
    },
    text: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    btnType: {
      type: String,
      default: 'primary',
    },
    opts: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, opts, valueKey } = toRefs(props);

    const selected = useComputed(modelValue, emit);
    const keys = computed(() => {
      return opts.value.map((x) => x[valueKey.value]);
    });

    const uncheckAll = () => {
      selected.value = selected.value.filter(
        (item) => !keys.value.includes(item),
      );
    };
    const checkAll = () => {
      uncheckAll();

      selected.value = [...selected.value, ...keys.value];
    };
    const count = computed(() => {
      // if (!_.isArray(opts.value)) return 0;
      const intersect = opts.value.filter((x) => {
        return selected.value.indexOf(x[valueKey.value]) > -1;
      });
      return intersect.length;
    });

    const dropdown = ref();
    function onClose() {
      dropdown.value.handleClose();
    }
    return { selected, count, uncheckAll, checkAll, dropdown, onClose };
  },
};
</script>
<style lang="sass" scoped>
:deep(.el-badge__content)
  z-index: 2
</style>
