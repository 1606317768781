<template>
  <!--  -->
  <El-Input
    v-model="model"
    clearable
    :placeholder="`請輸入${type == 1 ? nameLabel : idLabel}`"
    class="w-[400px] md:w-full"
    @change="onInput"
  >
    <template #prepend>
      <El-Select v-model="type" class="w-[110px]" @change="onChange">
        <el-option :label="nameLabel" value="1">{{ nameLabel }}</el-option>
        <el-option :label="idLabel" value="2">{{ idLabel }}</el-option>
      </El-Select>
    </template>
  </El-Input>
</template>
<script>
import { ref, watch } from 'vue';

import { ElOption, ElSelect, ElInput } from 'element-plus';

export default {
  components: { ElOption, ElSelect, ElInput },

  props: {
    nameLabel: {
      type: String,
      default: '試卷名稱',
    },
    idLabel: {
      type: String,
      default: '試卷ID',
    },
    name: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:name', 'update:id'],
  setup(props, { emit }) {
    const model = ref('');
    const type = ref('1');
    const update = () => {
      const val = {
        name: model.value,
        id: model.value,
      };
      emit('update:name', type.value === '1' && val.name ? val : null);
      emit('update:id', type.value === '2' && val.name ? val : null);
    };

    const onChange = () => {
      model.value = null;
      update();
    };
    const onInput = () => {
      update();
    };
    watch(props, (val) => {
      if (!val.name && !val.id) {
        model.value = null;
      }
    });

    return { model, type, onChange, onInput };
  },
};
</script>
<style lang="sass" scoped>
:deep(.el-input-group__prepend)
  @apply bg-white
  ::placeholder
    @apply text-black
  .el-icon
    @apply text-black
:deep
  .el-input-group__append
    @apply bg-primary text-white
</style>
