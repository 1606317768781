<template>
  <div>
    <Necker />
    <div class="py-8 container">
      <div class="box">
        <p class="text-xl">
          <span class="text-primary mr-4"
            >Hi , {{ user.name || '未命名' }}</span
          >
          <span>
            {{ leading[type] }}
          </span>
        </p>

        <div class="flex space-x-4 items-center mt-4 flex-wrap">
          <Keyword
            v-model:keyword="form.keyword"
            v-model:keywordType="form.keywordType"
            class="w-[400px] md:w-full md:mb-4"
          />
          <Btn-Filter
            id="btnFilter"
            @toggle="dialog.toggle({ show: true })"
            @reset="reset"
          ></Btn-Filter>

          <El-Button v-if="type === 'self'" :icon="Operation"
            >自組試卷</El-Button
          >
          <div class="!ml-auto flex items-center space-x-4 sm:mt-3">
            <Folder id="folder" />
          </div>
        </div>
      </div>
      <div v-loading="loading">
        <div v-if="listType === 'search'">
          <div class="flex flex-wrap my-6 justify-between items-center">
            <Data-Type
              v-model="form.dataType"
              class="sm:w-full w-auto"
              :opts="dataType"
              @update:modelValue="(e) => (form.pg = 1)"
            />
            <Sorter
              v-model:order="form.order"
              v-model:orderItem="form.orderItem"
              class="sm:w-full w-auto ml-auto sm:ml-0 sm:mt-4 mt-0"
              :opts="[
                { key: 'date', name: '考試日期' },
                { key: 'avgCorrectRate', name: '答題正確率' },
                { key: 'ansCnt', name: '作答次數' },
                { key: 'qCnt', name: '題目數量' },
              ]"
              @update:order="(e) => (form.pg = 1)"
            />
            <Display-Type
              v-if="!isMobile"
              class="ml-4"
              :display="display"
              @choose-type="switchDisplayType"
            />
          </div>

          <div class="my-6">
            <List :display="display" />
          </div>
        </div>
      </div>
      <div v-if="listType === 'recommend'">
        <RecommendList
          :empty-text="
            $route.query.type === 'topic' || $route.params.slug === 'topic' ? 'Coming Soon!' :null 
          "
        ></RecommendList>
      </div>
    </div>

    <El-Dialog
      v-model="dialog.state.show"
      title="進階搜尋"
      :destroy-on-close="true"
    >
      <component
        :is="activeFilter"
        v-if="activeFilter"
        @update="onFilter"
      ></component>
    </El-Dialog>

    <Tour />
  </div>
</template>

<script>
import { provide, ref, inject, computed, watch, reactive } from 'vue';
import { ElDialog, ElButton } from 'element-plus';
import { Operation, Edit } from '@element-plus/icons-vue';
import { useRoute } from 'vue-router';
import _ from 'lodash';

import Necker from '@/views/_necker/Index.vue';
import useDialog from '@/hook/useDialog';

import Keyword from '@/components/filter/Keyword.vue';
import Sorter from '@/components/filter/Sorter.vue';
import DisplayType from '@/components//filter/DisplayType.vue';
import DataType from '@/components/filter/DataType.vue';
import Titles from '@/components/Titles';
import BtnFilter from '@/components/BtnFilter.vue';

import useFolder from '@/modules/exam/folder/useFolder';
import Folder from '@/modules/exam/folder/Mini.vue';
import ExamFilter from '@/modules/filter/Exam.vue';
import SelfFilter from '@/modules/filter/Self.vue';

import List from '@/modules/exam/list/Index.vue';
import RecommendList from '@/modules/exam/list/Recommend.vue';
import useExam from '@/modules/exam/useExam';
import Exam from '@/api/Exam.js';
import usePgReset from '@/hook/usePgReset.js';
import Tour from './_Tour.vue';

export default {
  components: {
    ElDialog,
    ElButton,
    Necker,
    List,
    Keyword,
    Sorter,
    Folder,
    DataType,
    DisplayType,
    ExamFilter,
    SelfFilter,
    Titles,
    RecommendList,
    BtnFilter,
    Tour,
  },
  beforeRouteEnter(to, from, next) {
    usePgReset(to, from, next);
  },

  setup() {
    const { user } = inject('auth');
    const { leading } = inject('page');
    const isMobile = inject('isMobile');
    const route = useRoute();

    const folder = useFolder({ listRouteName: 'Exam.Question' });
    provide('folder', folder);

    const listType = computed(() => {
      return route.query.keyword || route.query.filterId
        ? 'search'
        : 'recommend';
    });

    const display = ref('card');
    const switchDisplayType = (type) => {
      display.value = type;
    };

    const exams = useExam({
      method: ref(Exam.list),
      defaultForm: {
        keywordType: 'name',
        dataType: 'all',
      },
    });

    provide('exams', exams);

    const { saveds, updateSaved } = exams;
    provide('actions', { saveds, updateSaved });

    watch(
      listType,
      (val, old) => {
        if (val != old && val === 'search') {
          exams.fetcher();
        }
      },
      { immediate: true },
    );

    const dialog = useDialog();
    provide('dialog', dialog);

    const activeFilter = computed(() => {
      return route.params.slug === 'past' || route.params.slug === 'topic'
        ? ExamFilter
        : SelfFilter;
    });

    const onFilter = (e) => {
      exams.form.filterId = e.filterId;
      exams.form.keyword = null;
      exams.form.keywordType = null;
      exams.form.pg = 1;
      dialog.toggle();
    };

    return {
      type: route.params.slug,
      form: exams.form,
      listType,
      loading: exams.loading,
      dataType: exams.dataType,
      reset: exams.reset,
      dialog,
      Operation,
      Edit,
      display,
      switchDisplayType,
      onFilter,
      user,
      isMobile,
      activeFilter,
      leading,
    };
  },
};
</script>

<style scoped lang="sass">
:deep(.el-loading-mask)
  z-index: 2
</style>
