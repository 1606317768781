<template>
  <div class="filter flex flex-col space-y-3 filter-theme-info">
    <IdName v-model:name="form.name" v-model:id="form.key" />
    <hr class="my-4" />
    <Category v-model="form.cKey"></Category>
    <hr class="my-4" />

    <Title v-model="form.stKeys" :c-key="form.cKey?.id" />
    <hr class="my-4" />

    <Subject-Type v-model="form.sbtKey" />

    <Subject v-model="form.sbjKeys" :sbt-key="form.sbtKey?.id" />

    <hr class="my-4" />

    <Period v-model="form.period" />

    <Ex-Period v-model="form.exPeriod" />

    <Score v-model="form.avgCorrectRate" />
    <!-- <QCnt v-model="form.qCnt" /> -->
    <div class="flex space-x-6 pt-4 justify-center">
      <el-button size="large" type="text" @click="reset">重新填寫</el-button>
      <el-button id="btnAddFilters" size="large" type="danger" @click="add_"
        >加入篩選條件</el-button
      >
    </div>

    <Results :rows="results" @remove="remove" @clear="clear" />

    <div class="pt-6 flex justify-center">
      <El-Button
        id="btnGetFilterResult"
        :disabled="!results.length || !changed"
        size="large"
        type="primary"
        round
        class="text-2xl min-w-[200px]"
        @click="submit"
        >顯示試卷</El-Button
      >
    </div>
  </div>
</template>

<script>
import { ElButton, ElMessage } from 'element-plus';
import Exam from '@/api/Exam';
import IdName from './form/IdName.vue';
import Category from './form/category/Index.vue';
import Title from './form/title/Index.vue';
import SubjectType from './form/subjectType/Index.vue';
import Subject from './form/subject/Checkbox.vue';
import Period from './form/period/Index.vue';
import ExPeriod from './form/exPeriod/Index.vue';
import Results from './_Results.vue';
import Score from './form/score/Index.vue';

import useFilter from './useFilter';
import { useRoute } from 'vue-router';

export default {
  components: {
    IdName,
    Category,
    Title,
    SubjectType,
    Subject,
    Period,
    ExPeriod,
    Score,
    Results,
    ElButton,
    // QCnt,
  },
  emits: ['update'],
  setup(props, { emit }) {
    const route = useRoute();
    const { form, add, remove, reset, results, getFilterId, clear, changed } =
      useFilter({
        defaultForm: {
          type: route.params.slug || 'past',
          name: null,
          key: null,
          cKey: null,
          stKeys: [],

          sbtKey: null,
          // sbtKey: { id: 'SBT00000003', name: '商' },
          sbjKeys: [],
          period: { id: 'all', name: '不拘' },
          exPeriod: { id: 'all', name: '不拘' },
          avgCorrectRate: { id: 'all', name: '不拘' },
          // qCnt: { id: 'all', name: '不拘' },
        },
      });

    const add_ = () => {
      if (!(form.name || form.key || form.cKey)) {
        ElMessage({
          message: '請輸入"試卷名稱/ID"或選擇"考試種類"',
          center: true,
        });
        return;
      }

      add();
    };

    const submit = () => {
      getFilterId({ API: Exam.filterId }).then((res) => {
        if (res.code === 1) {
          emit('update', { filterId: res.data.filterId });
        } else {
          ElMessage({
            message: '無法取得filter id',
            center: true,
          });
        }
      });
    };

    return {
      form,
      add_,
      remove,
      reset,
      results,
      submit,
      clear,
      changed,
    };
  },
};
</script>
