<template>
  <div
    class="flex items-center justify-center rounded-full bg-gray-200 w-7 h-7 text-gray-400 cursor-pointer duration-300 hover:text-white hover:bg-danger"
  >
    <font-awesome-icon :icon="['fas', 'trash']" class="text-base" />
  </div>
</template>
<script>
export default {
  setup() {
    return;
  },
};
</script>
<style lang=""></style>
