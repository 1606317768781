<template>
  <El-Input
    v-model="form.keyword"
    clearable
    :placeholder="
      form.keywordType === 'name' ? `請輸入試卷名稱` : `請輸入試卷ID`
    "
  >
    <template #prepend>
      <El-Select
        v-model="form.keywordType"
        placeholder="試卷名稱"
        class="w-[110px]"
      >
        <el-option label="試卷名稱" value="name">試卷名稱</el-option>
        <el-option label="試卷ID" value="id">試卷ID</el-option>
      </El-Select>
    </template>
    <template #append>
      <el-button :icon="Search" @click="submit">搜尋</el-button>
    </template>
  </El-Input>
</template>
<script>
import { reactive, toRefs } from 'vue';
import { Search } from '@element-plus/icons-vue';
import { ElOption, ElSelect, ElButton, ElInput } from 'element-plus';
import useWatch from '@/hook/useWatch';
import _ from 'lodash';

export default {
  components: { ElOption, ElSelect, ElButton, ElInput },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    keywordType: {
      type: String,
      default: '',
    },
  },
  emits: ['update:keyword', 'update:keywordType'],
  setup(props, { emit }) {
    const form = reactive({ keyword: '', keywordType: '' });

    const { keyword, keywordType } = toRefs(props);

    const onUpdated = (_form) => {
      emit('update:keyword', _form.keyword);
      emit('update:keywordType', _form.keywordType);
    };

    useWatch([keyword, keywordType], form, { immediate: true });

    const submit = () => {
      const _form = { ...form };
      _form.keywordType = !_form.keyword ? null : _form.keywordType || 1;
      onUpdated(_form);
    };

    return { form, submit, Search };
  },
};
</script>
<style lang="sass" scoped>
:deep(.el-input-group__prepend)
  @apply bg-white
  ::placeholder
    @apply text-black
  .el-icon
    @apply text-black
:deep
  .el-input-group__append
    @apply bg-primary text-white
</style>
