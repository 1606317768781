<template>
  <!-- <json-viewer :value="form"></json-viewer> -->
  <div class="-mx-4 px-4 bg-gray-100 border-t border-b shadow-inner">
    <div v-if="rows.length" class="sub-title py-6 flex items-center">
      篩選條件 :
      <!-- <span class="text-gray-400 text-sm mt-1 ml-2">(上限1000題)</span> -->
      <el-button class="ml-auto text-danger" link @click.prevent="clear">
        全部清除
      </el-button>
    </div>
    <div class="grid grid-cols-2 gap-4 md:grid-cols-1">
      <div v-for="(row, i) in rows" :key="row.id" class="">
        <div
          class="border border-primary flex items-center h-full w-full text-primary px-4 rounded py-3"
        >
          <div class="flex-grow max-h-20 overflow-auto">
            {{ row.text }}
          </div>
          <div class="flex-none cursor-pointer" @click="remove(i)">
            <font-awesome-icon
              :icon="['fas', 'times-circle']"
              class="text-danger text-xl"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // components: { ElButton },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['remove', 'clear'],
  setup(props, { emit }) {
    const remove = (idx) => {
      emit('remove', idx);
    };
    const clear = (idx) => {
      emit('clear', idx);
    };
    return { remove, clear };
  },
};
</script>
