<template>
  <div class="flex items-start">
    <El-Radio-Group
      v-model="form.key"
      :size="size"
      class="separate"
      @change="update"
    >
      <El-Radio-Button v-for="opt in opts" :key="opt.key" :label="opt.key">
        {{ opt.name }}
      </El-Radio-Button>
      <div
        v-if="form.key === 'custom'"
        class="flex border rounded mb-2 p-[3px]"
      >
        <slot :others="others"></slot>
      </div>
    </El-Radio-Group>
  </div>
</template>

<script>
import { toRefs, reactive, watch, ref } from 'vue';
import _ from 'lodash';
import { ElRadioGroup, ElRadioButton } from 'element-plus';
import { useDebounceFn } from '@vueuse/core';
export default {
  components: {
    ElRadioGroup,
    ElRadioButton,
  },
  props: {
    size: {
      type: String,
      default: 'default',
    },
    opts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelValue: {
      type: String,
      default: '',
    },
    othersDefault: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { othersDefault, modelValue, opts } = toRefs(props);
    const separator = '-';

    const form = reactive({ key: '' });
    const others = ref([...othersDefault.value]);
    watch(
      () => modelValue,
      (val) => {
        form.key = opts.value.some((x) => x.key === val.value)
          ? val.value
          : 'custom';
      },
      { immediate: true, deep: true },
    );

    const update = useDebounceFn((e) => {
      emit(
        'update:modelValue',
        form.key === 'custom' ? others.value.join(separator) : e,
      );
    }, 100);

    let old;
    watch(
      () => others,
      (val) => {
        console.log('update');
        if (!_.isEqual(val, old)) {
          old = _.cloneDeep(val);
          update();
        }
      },
      { deep: true },
    );

    return { form, update, others };
  },
};
</script>
