<template>
  <div>
    <Grid
      v-if="display === 'card'"
      :rows="rowsData.rows"
      :empty-text="emptyText"
    ></Grid>
    <TableList v-else :rows="rowsData.rows" :empty-text="emptyText"></TableList>
    <BtnMore v-if="!isEnding" @more="more"></BtnMore>
  </div>
</template>

<script setup>
import { inject, ref, reactive, provide, defineProps } from 'vue';
import BtnMore from '@/components/BtnMore.vue';
import TableList from './Table.vue';
import Grid from './Grid.vue';
import { Plus } from '@element-plus/icons-vue';

const props = defineProps({
  display: {
    type: String,
    default: 'table',
    require: true,
  },
  emptyText: {
    type: String,
    default: '目前沒有內容',
  },
});
const { rowsData, isEnding, more } = inject('exams');
</script>
