<template>
  <div
    class="flex flex-col h-full bg-white rounded shadow"
    :class="'card-' + idx"
  >
    <div
      class="py-4 px-5 cursor-pointer"
      v-soft-disabled="!permission.check(row.member_status)"
      @click="preview(row)"
    >
      <Header :row="row"></Header>
      <Info :row="row"></Info>
    </div>
    <div class="py-4 px-5 border-t mt-auto">
      <ActionBar :idx="idx" :row="row"></ActionBar>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import Info from './Info.vue';
import ActionBar from './ActionBar.vue';
import Header from './Header.vue';
export default {
  components: {
    Header,
    ActionBar,
    Info,
  },
  props: {
    idx: {
      type: Number,
      default: 0,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['preview'],
  setup(props, { emit }) {
    const permission = inject('permission');
    const preview = (row) => {
      // if (row.member_status != 1) return;
      emit('preview', row);
    };

    return { permission, preview };
  },
};
</script>

<style></style>
