import Subject from '@/api/Subject';
import { reactive, watch } from 'vue';

export default (key) => {
  const opts = reactive([]);

  const fetcher = () => {
    Subject.list({ sbtKey: key.value }).then((res) => {
      if (res.code === 1) {
        opts.splice(0, opts.length, ...res.data.rows);
      } else {
        opts.splice(0, opts.length);
      }
    });
  };

  watch(
    key,
    (val, old) => {
      if (val) {
        fetcher();
      } else {
        opts.splice(0, opts.length);
      }
    },
    { immediate: true },
  );

  return { opts };
};
