<template>
  <div>
    <div
      v-if="rows && rows.length"
      class="grid grid-cols-4 gap-4 lg:grid-cols-2 md:grid-cols-1"
    >
      <Card
        v-for="(row, i) in rows"
        :key="row.id"
        :row="row"
        :idx="i"
        @preview="preview"
      ></Card>
    </div>
    <div v-else>
      <div class="flex items-center justify-center p-10 text-xl">
        {{ emptyText }}
      </div>
    </div>
    <el-dialog
      v-model="previewDialog.state.show"
      custom-class="md:w-[94%] w-[900px]"
      :destroy-on-close="true"
    >
      <PreviewList
        :columns="['attribute']"
        :clickable="true"
        :sortable="false"
      ></PreviewList>
    </el-dialog>
  </div>
</template>
<script setup>
import { defineProps, provide } from 'vue';
import Card from '../card/index.vue';
import PreviewList from '@/modules/question/List.vue';
import usePreview from '@/modules/exam/usePreview';
import useDialog from '@/hook/useDialog';
import { ElDialog } from 'element-plus';
const props = defineProps({
  rows: { type: Array, default: () => [] },
  emptyText: {
    type: String,
    default: '目前沒有資料',
  },
});

const previewDialog = useDialog();
const {
  rows: questions,
  preview,
  loading: previewLoading,
  confs,
  updateConfs,
  saveds,
  updateSaved,
} = usePreview({ dialog: previewDialog });

provide('questions', {
  loading: previewLoading,
  rowsData: {
    rows: questions,
  },
  confs,
  updateConfs,
  saveds,
  updateSaved,

  isEnding: true,
});
</script>
