<template>
  <div class="flex space-x-4">
    <a
      v-for="opt in opts"
      :key="opt.key"
      href="#"
      class="text-sm"
      :class="opt.active ? 'text-black' : 'text-gray'"
      @click.prevent="toggle(opt)"
      >{{ opt.name }}
      <span
        class="inline-block align-middle duration-300"
        :class="opt.order == '1' ? 'rotate-180' : ''"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </a>
  </div>
</template>

<script>
import { reactive, watch, toRefs } from 'vue';

export default {
  props: {
    opts: { type: Array, default: () => [] },
    orderItem: {
      type: String,
      default: '',
    },
    order: {
      type: String,
      default: '',
    },
  },
  emits: ['update:order', 'update:orderItem'],
  setup(props, { emit }) {
    const { orderItem, order, opts } = toRefs(props);
    const form = reactive({
      orderItem: orderItem.value,
      order: order.value,
    });

    watch(
      [orderItem, order, opts],
      (val) => {
        if (val) {
          opts.value.forEach((x) => {
            x.order = '0';
            if (x.key === form.orderItem) {
              x.order = form.order;
              x.active = true;
            } else {
              x.active = false;
            }
          });
          form.orderItem = val[0];
          form.order = val[1];
        }
      },
      { immediate: true },
    );

    const onUpdated = (_form) => {
      emit('update:order', _form.order);
      emit('update:orderItem', _form.orderItem);
    };
    const toggle = (opt) => {
      if (opt.active) {
        form.order = opt.order == '1' ? '0' : '1';
      } else {
        opt.active = true;
        form.orderItem = opt.key;
        form.order = opt.order;
      }

      onUpdated({ ...form });
    };
    return { form, toggle };
  },
};
</script>
