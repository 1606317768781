<template>
  <div class="flex flex-wrap">
    <a
      v-for="opt in opts"
      :key="opt.id"
      href="#"
      class="text-sm py-2 border-primary mr-4"
      :class="
        model === opt.id
          ? 'text-primary border-b-2 font-bold'
          : 'text-gray  hover:opacity-75'
      "
      @click.prevent="toggle(opt)"
      >{{ opt.name }} <span v-if="opt.cnt">({{ opt.cnt }})</span></a
    >
    <a
      v-if="showDefaultBtn != '' && opts.length === 0"
      href="#"
      class="text-sm py-2 border-primary mr-4 text-primary border-b-2 font-bold"
      >{{ showDefaultBtn }}</a
    >
  </div>
</template>

<script>
import { toRefs, watch } from 'vue';
import useComputed from '@/hook/useComputed';

export default {
  props: {
    opts: { type: Array, default: () => [] },
    modelValue: {
      type: String,
      default: 'all',
    },
    showDefaultBtn: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue', 'update'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const { opts } = toRefs(props);

    const model = useComputed(modelValue, emit);

    const onUpdated = (id) => {
      model.value = id;
    };

    const toggle = (opt) => {
      if (opt.active) return;
      onUpdated(opt.id);
    };
    watch(model, (val) => {
      opts.value.forEach((x) => {
        x.active = x.id == val.id;
      });
    });
    return { model, toggle };
  },
};
</script>
