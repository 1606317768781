import Subject from '@/api/Subject';
import { reactive } from 'vue';

export default (key) => {
  const opts = reactive([
    {
      key: 'all',
      name: '不拘',
    },
    {
      key: '1m',
      name: '近1個月',
    },
    {
      key: '3m',
      name: '近三個月',
    },
    {
      key: '6m',
      name: '近六個月',
    },
    {
      key: '1y',
      name: '近一年',
    },
    {
      key: '3y',
      name: '近三年',
    },
  ]);

  return { opts };
};
